import { PaymentProvider } from '@acrelec-cloud/apico-shared';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import { DiscountComponent } from 'src/components/Discount/DiscountComponent';
import { useTranslate } from 'src/components/Languages/translate.hook';
import { CheckoutFormFake } from 'src/components/Payment/Fake/CheckoutFormFake';
import { CheckoutFormNepting } from 'src/components/Payment/Nepting/CheckoutFormNepting';
import { CheckoutFormStripe } from 'src/components/Payment/Stripe/CheckoutFormStripe';
import { useStore } from 'src/contexts/store.context';

import { SelectHourComponent } from '../PickUp/SelectHourComponent';

export const CheckoutPageInfos = observer(() => {
  const { translate } = useTranslate();
  const {
    basket: { setPickUpTime },
  } = useStore();

  const paymentProviders = process.env.REACT_APP_PAYMENT_SYSTEM.split(',');

  const [currentPaymentProvider] = useState<PaymentProvider>(paymentProviders[0] as PaymentProvider);

  const renderPaymentProvider = (paymentProvider: PaymentProvider) => {

    switch (paymentProvider) {
      case PaymentProvider.NEPTING:
        return <CheckoutFormNepting />;
      case PaymentProvider.STRIPE:
        return <CheckoutFormStripe />;
      case PaymentProvider.FAKE:
        return <CheckoutFormFake></CheckoutFormFake>;
      default:
        return <></>;
    }
  }

  return (
    <div className="checkout-page-left">
      <div className="checkout-page-left__content-wrapper select-hour__wrapper content-wrapper--padding">
        <SelectHourComponent
          title={translate('SelectHourComponent.title')}
          callback={setPickUpTime}
        />
      </div>
      <DiscountComponent />
      {renderPaymentProvider(currentPaymentProvider)}
    </div>
  );
});
