import { CurrentParts } from '@acrelec-cloud/apico-cdk';
import { ProductPartObject } from '@acrelec-cloud/apico-sdk';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { useTranslate } from 'src/components/Languages/translate.hook';
import { findCurrentPartByParent } from 'src/components/Products/Parts/utils/find-current-part.util';
import { useStore } from 'src/contexts/store.context';
import { useMemoCompare } from 'src/hooks/memo-compare.hook';

import { PartOption } from './PartOption';
import { PartRegular } from './PartRegular';
import { PartTitle } from './PartTitle';

interface PartRendererProps {
  // TODO: change any
  part: any;
  parent?: CurrentParts;
  onToggleCustomization: (parts: ProductPartObject[] | undefined) => void;
  insideOption?: boolean
}

export type ParentState = number[] | undefined;

export const PartRenderer = observer(({ part, parent, onToggleCustomization, insideOption }: PartRendererProps) => {
  const [optionCounter, setOptionCounter] = useState<string>();

  const {
    customization: { parts, required },
  } = useStore();

  const { translateProduct } = useTranslate();

  const findCustomizedPart = findCurrentPartByParent(toJS(required), {
    code: part.partCode,
    parent: part.parentArray,
  });

  const comparedCustomParts = useMemoCompare(
    () => toJS(parts),
    parts,
    // TODO: change any
    (a: any, b: any) =>
      a.length === b.length &&
      a.every((item: any, index: number) => {
        const otherItem = b[index] as any;
        return item.qty === otherItem.qty;
      }),
  );

  useEffect(() => {
    findCustomizedPart && setOptionCounter(`${findCustomizedPart?.qty}/${findCustomizedPart?.max}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparedCustomParts, findCustomizedPart?.max, findCustomizedPart?.qty]);

  const product = part.product!;
  const hasParts = !!product.parts && product.parts.length > 0;
  const hasOptions = !!product.options && product.options.length > 0;

  const singleChoice = hasParts && product.maxQuantity === product.minQuantity;
  const multipleChoices = !hasParts && hasOptions;
  const hasSiblings =
    (parent?.product?.options && parent.product.options.length > 1) ||
    (parent?.product?.parts && parent.product.parts.length > 1);

  if ((multipleChoices || singleChoice) && !hasSiblings) {
    const isOption = product.options && product.options.length > 0 ? true : false;

    return (
      <PartTitle
        name={translateProduct(part.product).name}
        product={product}
        hasParts={hasParts ? true : false}
        isOption={isOption}
        className={!parent ? 'txt-uppercase' : undefined}
        step={optionCounter}
        warnignMessageDisplay={false}
        onToggleCustomization={(parts) => onToggleCustomization(parts)}
      />
    );
  }

  const noChildren = !hasOptions && !hasParts;
  const isProduct = !hasOptions && hasParts && parent;
  const isOption = part.classificationCode && parent;



  if (noChildren || isProduct) {
    if (isOption) {
      return (
        <PartOption
          quantity={findCustomizedPart?.qty || 0}
          parent={parent!}
          product={part}
          hasChildren={isProduct && hasSiblings ? true : false}
          onToggleCustomization={(parts) => onToggleCustomization(parts)}
        />
      );
    }

    return (
      <PartRegular
        part={part.product}
        min={part.minQuantity}
        max={part.maxQuantity}
        qty={findCustomizedPart?.qty || 0}
        defaultQuantity={findCustomizedPart?.default || 0}
        parent={part.parentArray}
      />
    );
  }

  return (
    <div>
      {part.product.name}
    </div>
  );
});
