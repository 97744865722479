import { StripeCardElementOptions } from "@stripe/stripe-js";

export interface CheckoutFormFieldsType {
  defaultValue?: string;
  name: string;
  label: string;
  type: string;
  pattern?: RegExp;
  placeholder?: string;
  isRequired?: boolean;
  errorMessage?: string;
}

export const checkoutFormFields: CheckoutFormFieldsType[] = [
  // {
  //   name: "name",
  //   label: "name",
  //   type: "text",
  //   placeholder: "Jane Doe",
  //   isRequired: true,
  //   defaultValue: undefined,
  //   pattern: /[a-zA-Z -]+/i,
  //   errorMessage: "CheckoutPage.error.name"
  // },
  {
    name: "email",
    label: "email",
    type: "email",
    placeholder: "jane.doe@example.com",
    isRequired: true,
    defaultValue: undefined,
  },
  // {
  //   name: "phone",
  //   label: "phone",
  //   type: "text",
  //   isRequired: true,
  //   pattern: /^\+?[0-9() ]+$/i,
  //   errorMessage: "CheckoutPage.error.phone"
  // },
];

export const cardElementStyle: StripeCardElementOptions = {
  hidePostalCode: true,
  style: {
    base: {
      padding: "20px",
      fontSize: "16px",
      color: "#424770",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};
