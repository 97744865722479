import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import facebook from 'src/assets/images/icons/fb.svg';
import instagram from 'src/assets/images/icons/instagram.svg';
import {useTranslate} from 'src/components/Languages/translate.hook';
import {useStore} from 'src/contexts/store.context';

export const Footer = observer(() => {
	const {translate} = useTranslate();
	const location = useLocation();
	const restaurantsPage =
		location.pathname.includes('restaurants') && !location.pathname.includes('authenticate');
	const productPage = location.pathname.includes('product');
	const fitMain = useRef('special-footer');

	const {
		restaurant: {currentRestaurant, restaurants},
	} = useStore();

	const addPadding = useCallback(
		() => currentRestaurant && restaurantsPage && restaurants && restaurants.length > 0,
		[restaurants, currentRestaurant, restaurantsPage],
	);

	useEffect(() => {
		if (addPadding() || productPage) {
			const bodyClasses = document.body.classList;
			!bodyClasses.contains(fitMain.current) && document.body.classList.add(fitMain.current);
		} else {
			document.body.classList.remove(fitMain.current);
		}
	}, [addPadding, productPage]);

	return (
		<footer
			className={`footer${addPadding() ? ' footer--tablet-padding' : ''}${
				productPage ? ' footer--padding' : ''
			}`}>
			{/* <div className="social__links">
				<div className="product__container theme-container">
					<a href="https://www.facebook.com/StarsDriveIn/" target="_blank" rel="noreferrer">
						<ReactSVG src={facebook} width={30} height={30} />
					</a>
					{/* <a href="" target="_blank" rel="noreferrer">
						<ReactSVG src={twitter} width={30} height={30} />
					</a> 
					<a href="https://www.instagram.com/starsdrivein/" target="_blank" rel="noreferrer">
						<ReactSVG src={instagram} width={30} height={30} />
					</a>
					{/* <a href="https://www.facebook.com/LittleCaesars" target="_blank" rel="noreferrer">
						<ReactSVG src={linkedin} width={30} height={30} />
					</a> 
				</div>
			</div> */}
			<div className="footer__container theme-container">
				<div className="footer-block__left">
					{/* <a
						href={process.env.REACT_APP_CONTACT_PAGE_URL}
						target="_blank"
						rel="noopener noreferrer"
						className="footer__button margin-right">
						{translate(`Footer.contact`)}
					</a>
					<a
						href={process.env.REACT_APP_TERM_OF_USE_PAGE}
						target="_blank"
						rel="noopener noreferrer"
						className="footer__button">
						{translate(`Footer.legalsInformations`)}
					</a> */}
				</div>
				<div className="footer-block__center">
					<ul className='footer__links'>
						<li>
							<a
							href={process.env.REACT_APP_TERM_OF_USE_PAGE}
							target="_blank"
							rel="noopener noreferrer"
							className="footer__button">
							{translate(`Footer.legalsInformations`)}
							</a> 
						</li>
						<li>
							<a
								href={process.env.REACT_APP_ALLERGENS_URL}
								target="_blank"
								rel="noopener noreferrer"
								className="footer__button">
								{translate(`Footer.allergensInformations`)}
							</a>
						</li>
						{/* <li>
							<a href="#" target="_blank">
								Privacy Policy
							</a>
						</li> */}
						{/*<li>
							 <a
								href="https://starsdrivein.com/wp-content/uploads/2021/03/Menu-Nutrition-Report-5-2020.pdf"
								target="_blank"
								rel="noreferrer">
								Nutrition
							</a> 
						</li>*/}
					</ul>
					<p className="txt-center">
					Bonchon, Inc. All rights reserved. Bonchon® name, logos and related marks are trademarks licensed to Bonchon, Inc.
						<br /> This site is protected by reCAPTCHA and the Google Privacy Policy and Google
						Terms of Service apply.<br /> Powered by&nbsp;
						<a className="footer__button" target="blank" href="https://acrelec.com/">
							{translate(`Footer.copyright_link`)}
						</a>
					</p>
				</div>
				<div className="footer-block__right">
					<div
						className="footer__button round round--up"
						onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}></div>
				</div>
			</div>
		</footer>
	);
});
