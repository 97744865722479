import {RestaurantObject} from '@acrelec-cloud/apico-sdk';
import {GoogleMap, useJsApiLoader} from '@react-google-maps/api';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useRef, useState} from 'react';

import {useStore} from 'src/contexts/store.context';
import {useGeolocation} from 'src/hooks/geolocation.hook';

import {LoadingMap} from './LoadingMap';
import {gMap, GoogleMapsGeolocation} from './map.config';
import {MapMarker} from './MapMarker';

export type OpenInfoIndexType = number | undefined;

interface MapComponentProps {
	restaurantToDisplayId?: number;
	userLocation: any;
	distanceFormat: any;
}
export const MapComponent = observer((props: MapComponentProps) => {
	const {restaurantToDisplayId, userLocation, distanceFormat} = props;
	const initialRef = useRef(true);
	const {checkLocation, defineMapLocation} = useGeolocation();
	const [centerMap, setCenterMap] = useState<GoogleMapsGeolocation>();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [openInfoIndex, setOpenInfoIndex] = useState<OpenInfoIndexType>(0);

	const {
		restaurant: {restaurants},
	} = useStore();

	const {isLoaded} = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
	});

	useEffect(() => {
		setOpenInfoIndex(
			restaurants?.findIndex((restaurant: RestaurantObject) => {
				return restaurant.id === restaurantToDisplayId;
			}),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [restaurants, restaurantToDisplayId, userLocation]);

	useEffect(() => {
		const geolocation = defineMapLocation();
		setCenterMap(geolocation);
	}, [restaurantToDisplayId]);

	useEffect(() => {
		if (userLocation) {
			const geolocation = {
				lat: userLocation.latitude,
				lng: userLocation.longitude,
			};
			setCenterMap(geolocation);
		}
	}, [userLocation]);

  const gmap = gMap();

	return (
		<div className="map-container">
			{isLoaded ? (
				<GoogleMap mapContainerStyle={gmap.containerStyle} center={centerMap} zoom={gmap.zoom}>
					<>
						{restaurants?.map((restaurant: RestaurantObject, index: number) => (
							<MapMarker
								key={index}
								OpenInfoIndexState={[openInfoIndex, setOpenInfoIndex]}
								index={index}
								restaurant={restaurant}
								userLocation={userLocation}
								distanceFormat={distanceFormat}
								showInfoWindow={
									openInfoIndex === index &&
									checkLocation(restaurant.geolocation.latitude, restaurant.geolocation.longitude)
								}
							/>
						))}
					</>
				</GoogleMap>
			) : (
				<LoadingMap />
			)}
		</div>
	);
});
