import {observer} from 'mobx-react-lite';
import React, {useMemo, useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import basket from 'src/assets/images/icons/basket.svg';
import {useTranslate} from 'src/components/Languages/translate.hook';
import {ComponentNameTypes, PopoverTypes} from 'src/components/Popover/custom-popovers.config';
import {usePopover} from 'src/components/Popover/popover.hook';
import {SearchEngine} from 'src/components/Products/SearchEngine/SearchEngine';
import {CurrentRestaurantModal} from 'src/components/Restaurant/CurrentRestaurantModal';
import {useStore} from 'src/contexts/store.context';
import {useBrand} from 'src/hooks/brand.hook';

import {CheckoutNavbar} from './CheckoutNavbar';

export const Navbar = observer(() => {
	const {openPopover} = usePopover();
	const {translate} = useTranslate();
	const history = useHistory();
	const location = useLocation();
	const {lightLogo} = useBrand();
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

	const {
		restaurant: {currentRestaurant},
	} = useStore();

	const {
		basket: {productCount},
		order: {totalPendingOrders, totalValidatedOrders},
	} = useStore();

	const isTimerActive = useMemo(() => {
		return location.pathname.includes('current-order');
	}, [location.pathname]);

	// Change the navbar on checkout page
	if (location.pathname.includes('checkout')) {
		return <CheckoutNavbar />;
	}

	return (
		<header className="navbar">
			<div className="navbar__container">
				<div className="navbar__block navbar__block--left">
					{/* <button
						className="navbar__button navbar__button--menu"
						onClick={() =>
							openPopover(PopoverTypes.LEFT, ComponentNameTypes.MenuComponent)
						}></button> */}
					{(location.pathname.startsWith('/order') || location.pathname.startsWith('/product')) && (
						<SearchEngine mode="navbar" />
					)}
				</div>
				<div className="navbar__block navbar__block--center">
					<Link to="/order" className="navbar__block--home-button">
						{lightLogo && <img src={lightLogo} alt={process.env.REACT_APP_BRAND_NAME} />}
					</Link>
				</div>
				<div className="navbar__block navbar__block--right">
					{currentRestaurant && (
						<button
							className="navbar__button"
							onClick={() => setModalIsOpen(true)}
							title={translate(`Navbar.store`)}>
							<p className="current-restaurant__paragraph--text txt-m txt-bold">
								{translate(`CurrentRestaurant.collect_from`)} {currentRestaurant.name}
							</p>
						</button>
					)}
					{totalPendingOrders > 0 && (
						<button
							onClick={() => history.push('/current-order')}
							className={`navbar__button navbar__button--timer notification txt-xxs ${
								isTimerActive && 'navbar__button--active'
							}`}
							data-notification={totalValidatedOrders > 0 ? totalValidatedOrders : null}
							title={translate(`Navbar.timer`)}></button>
					)}
					<CurrentRestaurantModal open={modalIsOpen} onClose={() => setModalIsOpen(false)} />
					<button
						className={`navbar__button ${productCount && 'notification txt-xxs'}`}
						data-notification={productCount}
						onClick={() => openPopover(PopoverTypes.RIGHT, ComponentNameTypes.Basket)}
						title={translate(`Navbar.basket`)}>
						<ReactSVG className="navbar__button--basket" src={basket} />
					</button>
				</div>
			</div>
		</header>
	);
});
