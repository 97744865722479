import {observer} from 'mobx-react-lite';
import React from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {SearchRestaurant} from 'src/components/Restaurant/SearchRestaurant';

interface RestaurantFinderProps {
	onError?: (errorStatus: boolean) => void;
}

export const RestaurantFinder = observer(({onError}: RestaurantFinderProps) => {
	const {translate} = useTranslate();

	return (
		<div className="profile">
			<p className="profile__title txt-h1">{translate('RestaurantFinder.title')}</p>
			<p className="profile__subtitle txt-h3">{translate('RestaurantFinder.subtitle')}</p>
			<SearchRestaurant></SearchRestaurant>
		</div>
	);
});
