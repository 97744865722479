import { IdParam, RestaurantObject } from '@acrelec-cloud/apico-cdk';
import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

import { LoadingSpinner } from 'src/components/LoadingSpinner/LoadingSpinner';
import { useStore } from 'src/contexts/store.context';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const RestaurantDispatcher = () => {
  const { slugOrId } = useParams<IdParam>();
  const query = useQuery();
  const history = useHistory();
  const {
    basket,
    restaurant: {
      fetchRestaurant,
      setCurrentRestaurant,
    },
  } = useStore();

  useEffect(() => {
    const tableNumber = query.has('tableNumber') ? query.get('tableNumber') : null;
    if (slugOrId) {
      fetchRestaurant(slugOrId)
        .then((restaurant: RestaurantObject) => {
          if (!restaurant.authenticationRequired) {
            setCurrentRestaurant(restaurant);
            basket.setTableNumber(tableNumber ?? undefined);
            basket.setPickupMode("pickup");
            history.push('/order');
          } else {
            history.push(`/restaurants/${slugOrId}/authenticate`);
          }
        })
        .catch(() => history.push('/404'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slugOrId, setCurrentRestaurant, fetchRestaurant]);

  return (
    <main className="restaurant-dispacher">
      <LoadingSpinner />
    </main>
  );
};
