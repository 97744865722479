import {
  OutageType,
  ProductObject,
  RestaurantOutageProductsObject,
} from '@acrelec-cloud/apico-shared';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { observer } from 'mobx-react-lite';
import React, { useLayoutEffect, useMemo, useState } from 'react';

import { Filters } from 'src/components/Filters/Filters';
import { useStore } from 'src/contexts/store.context';

import { ProductItem } from './ProductItem/ProductItem';

type ProductListProps = {
  currentNavigation?: string;
}

export const ProductList = observer((props: ProductListProps) => {
  const { currentNavigation } = props;
  const store = useStore();
  const {
    navigation: {
      filteredProducts,
      hasFilters,
      navigation,
      currentClassifications,
      setCurrentNavigation,
    },
    restaurant: { currentRestaurant },
  } = store;

  const productsByNavigation = useMemo(() => {
    return navigation.map((productNavigation) => ({
      ...productNavigation,
      products: currentClassifications
        .filter(
          (productClassification) => productClassification.navigationId === productNavigation.id,
        )
        .map((productClassification) =>
          filteredProducts.find((product) => product.code === productClassification.productCode),
        )
        .reduce((products, item) => products.concat([item]), [])
        .sort((a, b) => a.priority - b.priority),
    }));
  }, [currentClassifications, filteredProducts, navigation]);

  const [productToDisplay, setProductToDisplay] = useState<number | undefined>();

  useLayoutEffect(() => {
    const onActive = (elements: Element[]) => {
      const navigations = navigation || [];
      const [element] = elements;
      const triggerNavigation = navigations.find(
        (nav) => nav.id.toString() === element.getAttribute('data-navigation'),
      );
      if (triggerNavigation) {
        setCurrentNavigation(triggerNavigation.id);
      }
    };

    let triggers = ScrollTrigger.batch('[data-navigation]', {
      onEnter: onActive,
      onEnterBack: onActive,
      start: 'top 60%',
      end: 'bottom 60%',

    });

    return () => triggers.forEach((trigger) => trigger.disable());
  }, [navigation, setCurrentNavigation]);

  useLayoutEffect(() => {
    if (currentNavigation) {
      const element = document.querySelector(`#navigation-${currentNavigation}`);

      if (element) {
        window.scrollTo({
          top: element.offsetTop - 200,
          behavior: 'smooth',
        });
      }
    }
  }, [currentNavigation]);

  return (
    <>
      <div className="header-product__list">
        <div className="filters-container">{hasFilters && <Filters />}</div>
        {/* <SearchEngine /> */}
      </div>
      {productsByNavigation.map((productNavigation) => {
        const products = productNavigation.products.filter((item: ProductObject) => {
          if (!item) {
            return false;
          }
          const productOutage =
            currentRestaurant.outageProducts &&
            currentRestaurant.outageProducts.find(
              (outage: RestaurantOutageProductsObject) => outage.productCode === item.code,
            );
          return (!productOutage || productOutage.state !== OutageType.DISABLED)
        })
        if (!products.length) {
          return <></>
        } 
        return (
          <>
            <p className="product-list__title">{productNavigation.displayName}</p>
            <div
              key={productNavigation.id}
              id={`navigation-${productNavigation.id}`}
              className="product-list"
              data-navigation={productNavigation.id}>
              {products.map((item: ProductObject) => {
                return (
                  <ProductItem
                    key={item.code}
                    item={item}
                    productToDisplay={productToDisplay}
                    setProductToDisplay={setProductToDisplay}
                    enableAddToCart
                  />
                );
              })}
            </div>
          </>
        )
      })}
    </>
  );
});
